import { requireFun } from '@/utils'
import { isMoney, numMaxConstruct } from '@/utils'

export function addUserField() {
  return [
    {
      label: '是否收取贴标费',
      prop: 'isCollectLabelCharget',
      name: 'isCollectLabelCharget',
      colNum: 24,
      type: 'radio',
      dicData: [
        {
          label: '否',
          value: 0
        },
        {
          label: '是',
          value: 1
        }
      ],
      rule: [
        {
          required: true,
          message: requireFun('是否收取贴标费必选')
        }
      ]
    },
    {
      label: '贴标费',
      prop: 'labelCharget',
      name: 'labelCharget',
      slotName: 'labelCharget',
      hide: !this.form.isCollectLabelCharget,
      rule: [
        {
          required: true,
          message: requireFun('贴标费')
        },
        {
          validator: isMoney
        },
        {
          validator: numMaxConstruct(9999)
        }
      ]
    }
  ].filter(({ hide }) => !hide)
}
